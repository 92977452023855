import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import axios from "axios";
import toast from "react-hot-toast";
 
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
 
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};
 
    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
 
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
 
const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
 
export default () => {
  const [response, setResponse] = useState(null)
  const [mailInputs, setMailInputs] = useState({
    name: "",
    email: "",
    message: "",
    templateType:"Pragmatiq", 
  })
 
  const handleClick = async (e)=>{
    toast.loading('Sending Mail')
    e.preventDefault();
    try {
      //  const response =  await axios.post('https://pragmatiq.in/sendEmail', mailInputs);
      const response =  await axios.post('http://localhost:6543/sendEmail', mailInputs);
     
            console.log(response,"response printing ")
      if(response.status===200){
        setResponse("Mail Sent Successfully");
        setMailInputs({
          name: "",
          email: "",
          message: "",
          // templateType:"Pragmatiq",
        
        });
      }
      else {
        setResponse("Something Went Wrong");
      }
      console.log(response);
      // toast.message('Mail Sent Successfully');
    } catch (error) {
      console.log(error);
      // toast.error('Something Went Wrong');
      setResponse("Something Went Wrong");
      console.log(error);
    }
  }
 
  return (
    <Container >
      <Content >
        <FormContainer style={{backgroundColor:'#006D5B'}}>
          <div tw="mx-auto max-w-4xl " >
            <h2>Contact us to know how we can help achieve your SDG goals?</h2>
            <form action="#">
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input id="name-input" type="text" name="name" placeholder="E.g. Pragmatiq" onChange={(e)=>setMailInputs(prev=>{
                      return {
                        ...prev,
                        name: e.target.value
                      }
                    })} />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" placeholder="E.g. pragmatiq@gmail.com" onChange={(e)=>setMailInputs(prev=>{
                      return {
                        ...prev,
                        email: e.target.value
                      }
                    })} />
                  </InputContainer>
                 
                </Column>
                <Column >
                <InputContainer style={{ display: 'flex', }}>
                    <Label htmlFor="name-input">Your Message</Label>
                    <Input id="message-input" name="message" placeholder="E.g. Details about your event" onChange={(e)=>setMailInputs(prev=>{
                      return {
                        ...prev,
                        message: e.target.value
                      }
                    })}/>
                  </InputContainer>
                </Column>
              </TwoColumn>
 
              <SubmitButton type="submit" onClick={handleClick} value="Submit" style={{backgroundColor: 'white', color: '#006D5B'}}>Submit</SubmitButton>
               {
                response ? (
                  <p>{response}</p>): null
               }
            </form>                    
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};